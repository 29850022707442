import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    delete require.cache[require.resolve('../../../assets/js/script.js')];
    require('../../../assets/js/script.js');
  }

  onActivate(event) {
    window.scroll(0, 0);
    // const scrollToTop = window.setInterval(() => {
    //   const pos = window.pageYOffset;
    //   if (pos > 0) {
    //     window.scrollTo(0, pos - 200); // how far to scroll on each step
    //   } else {
    //     window.clearInterval(scrollToTop);
    //   }
    // }, 16);
  }
}

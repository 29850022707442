import { Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

export const AppRoutes: Routes = [
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './home/home.module#HomeModule'
            },
            {
                path: 'home',
                loadChildren: './home/home.module#HomeModule'
            },
            {
                path: 'partners',
                loadChildren: './partner/partner.module#PartnerModule'
            },
            {
                path: 'about',
                loadChildren: './about-us/about-us.module#AboutUsModule'
            },
            {
                path: 'news',
                loadChildren: './news/news.module#NewsModule'
            },
            {
                path: 'leagues',
                loadChildren: './league/league.module#LeagueModule'
            },
            {
                path: 'contact',
                loadChildren: './contact/contact.module#ContactModule'
            }]
    }, {
        path: '**',
        redirectTo: 'home'
    }
];
